//
// SIGN IN WITH EMAIL/PASSWORD
//
export const SIGN_IN_WITH_PASSWORD_URL =
  'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=';

export interface SignInWithPasswrodRequest {
  email: string;
  password: string;
  returnSecureToken: boolean;
}

export interface SignInWithPasswrodResponse {
  idToken: string;
  email: string;
  displayName: string;
  refreshToken: string;
  expiresIn: string;
  localId: string;
  registered: boolean;
  profilePicture: string;
  kind: string;
}

//
// CHANGE PASSWORD
//
export const CHANGE_PASSWORD_URL =
  'https://identitytoolkit.googleapis.com/v1/accounts:update?key=';

export class ChangePasswordRequest {
  idToken: string;
  password: string;
  returnSecureToken: boolean = true;

  constructor(idToken: string, password: string) {
    this.idToken = idToken;
    this.password = password;
  }
}

export interface ChangePasswordResponse {
  localId: string;
  email: string;
  passwordHash: string;
  idToken: string;
  refreshToken: string;
  expiresIn: string;
}

//
// REFRESH TOKEN
//
export const REFRESH_TOKEN_URL =
  'https://securetoken.googleapis.com/v1/token?key=';

export interface TokenReqest {
  grant_type: string;
  refresh_token: string;
}

export interface TokenResponse {
  expires_in: string;
  token_type: string;
  refresh_token: string;
  id_token: string;
  user_id: string;
  project_id: string;
}

//
// ERROR
//
export interface Error {
  error: {
    code: number;
    message: string;
    errors: Array<string>;
  };
}

//
// IDP LOGIN URL
//
export const IDP_LOGIN_URL =
  'https://identitytoolkit.googleapis.com/v1/accounts:signInWithIdp?key=';

export interface IdpSignInRequest {
  requestUri: string;
  postBody: string;
  returnSecureToken: boolean;
  returnIdpCredential: boolean;
}

export interface IdpSignInResponse {
  federatedId: string;
  providerId: string;
  localId: string;
  emailVerified: boolean;
  email: string;
  oauthIdToken: string;
  oauthAccessToken: string;
  oauthTokenSecret: string;
  rawUserInfo: string;
  firstName: string;
  lastName: string;
  fullName: string;
  displayName: string;
  photoUrl: string;
  idToken: string;
  refreshToken: string;
  expiresIn: string;
  needConfirmation: boolean;
}

//
// SEND-OOB-CODE
//
export const SEND_OOB_CODE_URL =
  'https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=';

export interface SendOobCodeRequest {
  requestType: string;
  idToken: string;
}

export class SendOobCodeResponse {
  email: string;

  constructor(email: string) {
    this.email = email;
  }
}

//
// CONFIRM EMAIL
//
export const CONFIRM_EMAIL_URL =
  'https://identitytoolkit.googleapis.com/v1/accounts:update?key=';

export class ConfirmEmailRequest {
  oobCode: string;

  constructor(oobCode: string) {
    this.oobCode = oobCode;
  }
}

export interface ConfirmEmailResponse {
  email: string;
  emailVereified: boolean;
}

export const UPDATE_PROFILE_URL =
  'https://identitytoolkit.googleapis.com/v1/accounts:update?key=';

export class UpdateProfileRequest {
  idToken: string;
  displayName: string;
  photoUrl: string;
  deleteAttribute?: string[];
  returnSecureToken?: boolean;

  constructor(
    idToken: string,
    displayName: string,
    photoUrl: string,
    deleteAttribute?: string[],
    returnSecureToken?: boolean
  ) {
    this.idToken = idToken;
    this.displayName = displayName;
    this.photoUrl = photoUrl;
    this.deleteAttribute = deleteAttribute;
    this.returnSecureToken = returnSecureToken;
  }
}

export interface UpdateProfileResponse {
  email: string;
  displayName: string;
  photoUrl: string;
  idToken: string;
  refreshToken: string;
  expiresIn: string;
}

//
// API
//
export type TokenResponseApi = TokenResponse | Error;

export type SignInWithPasswrodResponseApi = SignInWithPasswrodResponse | Error;

export type IdpSignInResponseApi = IdpSignInResponse | Error;

export type SendOobCodeResponseApi = SendOobCodeResponse | Error;

export type ConfirmEmailResponseApi = ConfirmEmailResponse | Error;

export type UpdateProfileApi = UpdateProfileResponse | Error;

export interface User {
  readonly idToken: string;
  readonly refreshToken: string;
  readonly displayName: string;
  readonly email: string;
  readonly photoURL?: string;
  readonly localId: string;
}

//
// UTILS
//
export class FirebaseToken {
  firebaseToken: FirebaseTokenData;

  constructor(idToken: string) {
    this.firebaseToken = convertBearerClaim(idToken);
  }

  isExpired(): boolean {
    const now = new Date().getTime();
    return this.firebaseToken.exp * 1000 < now;
  }
}

export interface FirebaseTokenFirebase {
  // key: email, google.com, facebook.com etc.
  identities: Map<string, Array<string>>;
  sign_in_provider: string;
}

export interface FirebaseTokenData {
  admin: boolean;
  user_id: string;
  email: string;
  email_verified: boolean;
  firebase: FirebaseTokenFirebase;
  exp: number;
  iat: number;
  aud: string;
  auth_time: number;
  iss: string;
  sub: string;
}

const convertBearerClaim = (token: string): FirebaseTokenData => {
  return JSON.parse(atob(token.split('.')[1]));
};
