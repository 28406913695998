//
// LOGIN - USER/PASSWORD
//
export const FUNCTION_URL_LOGIN = '/firebase/login';

export interface UserPassLoginRequest {
  username: string;
  password: string;
  turnstileToken: string;
}

export interface LoginResponse {
  displayName: string;
  idToken: string;
  email: string;
  expiresIn: string;
  localId: string;
  profilePicture: string;
  refreshToken: string;
  registered: boolean;
}

//
// REFRESH TOKEN
//
export const FUNCTION_URL_REFRESH_TOKEN = '/firebase/refresh';

export interface RefreshTokenRequest {
  refreshToken: string;
  userId: string;
}

export interface RefreshTokenResponse {
  idToken: string;
  refreshToken: string;
}

//
// CHANGE PASSWORD
//
export const FUNCTION_URL_CHANGE_PASSWORD = '/firebase/change-password';

export interface ChangePasswordRequest {
  userId: string;
  idToken: string;
  email: string;
  oldPassword: string;
  newPassword: string;
}

export interface ChangePasswordResponse {
  idToken: string;
  refreshToken: string;
}

//
// TURNSTILE - SITE VERIFY
//
export const SITE_VERIFY_URL =
  'https://challenges.cloudflare.com/turnstile/v0/siteverify';

export interface SiteVerifyResponse {
  success: string;
  'error-codes': Array<string>;
  cdata: string;
  action: string;
  hostname: string;
}

export interface SiteVerifyRequest {
  secret: string;
  response: string;
  remoteip?: string | null;
  idempotencyKey?: string | null;
}

//
// OATUH LOGIN
//
export const FUNCTION_URL_OAUTH = '/firebase/oauth';

export class OauthLoginRequest {
  idToken: string;
  turnstileToken: string;
  provider: string;
  requestUri: string;

  constructor(
    idToken: string,
    turnstileToken: string,
    provider: string,
    requestUri: string
  ) {
    this.idToken = idToken;
    this.turnstileToken = turnstileToken;
    this.provider = provider;
    this.requestUri = requestUri;
  }
}

export interface OauthLoginResponse {
  displayName: string;
  idToken: string;
  email: string;
  expiresIn: string;
  localId: string;
  photoUrl: string;
  refreshToken: string;
  emailVerified: boolean;
}

//
// VERIFY EMAIL
//
export const FUNCTION_VERIFY_EMAIL = '/firebase/verify-email';

export class VerifyEmailRequest {
  locale: string;
  idToken: string;

  constructor(locale: string, idToken: string) {
    this.locale = locale;
    this.idToken = idToken;
  }
}

export interface VerifyEmailResponse {
  email: string;
}

//
// CONFIRM EMAIL
//
export const FUNCTION_CONFIRM_EMAIL = '/firebase/confirm-email';

export class ConfirmEmailRequest {
  oobCode: string;

  constructor(oobCode: string) {
    this.oobCode = oobCode;
  }
}

export interface ConfirmEmailResponse {
  email: string;
  emailVerified: boolean;
}

//
// UPDATE PROFILE
//
export const FUNCTION_UPDATE_PROFILE = '/firebase/update-profile';

export class UpdateProfileRequest {
  idToken: string;
  displayName: string;
  photoUrl: string;

  constructor(idToken: string, displayName: string, photoUrl: string) {
    this.idToken = idToken;
    this.displayName = displayName;
    this.photoUrl = photoUrl;
  }
}

export interface UpdateProfileResponse {
  idToken: string;
  refreshToken: string;
  expiresIn: string;
}
